import { useRoutes } from "react-router-dom";
// import loadable from "@loadable/component";
// import { lazy } from "react";

// render - utilities
// const Home = loadable(lazy(() => import("../pages/Home")));
// const SignUp = loadable(lazy(() => import("../pages/SignUp")));
// const Unsubscribe = loadable(lazy(() => import("../pages/Unsubscribe")));
// const NotFound = loadable(lazy(() => import("../pages/NotFound")));

import App from "../App";
import Home from "../pages/Home";
import SignUp from "../pages/SignUp";
import Unsubscribe from "../pages/Unsubscribe";
import NotFound from "../pages/NotFound";

// ==============================|| MAIN ROUTING ||============================== //

const routes = {
  path: "/",
  element: <App />,
  children: [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/signup",
      element: <SignUp />,
    },
    {
      path: "unsubscribe",
      element: <Unsubscribe />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ],
};

export default function Routes() {
  return useRoutes([routes]);
}
