import React, { useState, useEffect } from "react";
import { functions } from "../firebase";
import { httpsCallable } from "firebase/functions";

const unsubscribe = httpsCallable(functions, "unsubscribe");
const deleteUser = httpsCallable(functions, "deleteUser");

const params = new URLSearchParams(window.location.search);
const email = params.get("email");

export default function Unsubscribe() {
  const [loading, setLoading] = useState(true);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    if (email) {
      unsubscribe({ email: email }).then((result) => {
        if (result.data.status === "success") {
          setUnsubscribed(true);
          setLoading(false);
        }
      });
    }
  }, []);

  function handleDeleteUser() {
    deleteUser({ email: email }).then((result) => {
      if (result.data.status === "success") {
        setDeleted(true);
      }
    });
  }

  if (loading) {
    return (
      <div
        className="p-7 font-bold w-full h-screen flex flex-col justify-center
        text-light-text bg-light-neutral
        dark:text-dark-text dark:bg-dark-neutral"
      >
        <div className="text-4xl mb-3">Loading...</div>
        <div className="mb-2">
          <span className="text-light-secondary dark:text-white">
            Please wait while we unsubscribe you from brief.
          </span>
        </div>
      </div>
    );
  }

  if (unsubscribed) {
    return (
      <div
        className="p-7 font-bold w-full h-screen flex flex-col justify-center
    text-light-text bg-light-neutral
    dark:text-dark-text dark:bg-dark-neutral"
      >
        <div className="text-4xl mb-3">Sorry to see you go!</div>
        <div className="mb-2">
          <span className="text-light-secondary dark:text-white">
            You have been successfully unsubscribed from brief.
          </span>{" "}
          You will no longer receive daily emails at{" "}
          <span className="text-light-accent dark:text-dark-acccent">
            {email}
          </span>
          .
        </div>
        <div className="mb-2">
          Press the button below if you'd like us to permanently delete your
          information from our database.
        </div>
        <div>
          {deleted ? (
            <button
              className="rounded-xl border-2 px-4 py-2 mb-2 
            outline outline-2 outline-transparent hover:outline-offset-2 transition-all
            bg-light-accent text-light-textInputSecondary hover:bg-light-accent border-light-primary hover:outline-light-secondary font-medium tracking-wide
            dark:bg-dark-accent dark:text-dark-text dark:hover:bg-dark-accent dark:border-dark-primary dark:hover:outline-dark-secondary"
            >
              Information Deleted
            </button>
          ) : (
            <button
              onClick={handleDeleteUser}
              className="rounded-xl border-2 px-4 py-2 mb-2
                   outline outline-2 outline-transparent hover:outline-offset-2 transition-all
                   bg-light-accent text-light-textInputSecondary hover:bg-light-accent border-light-primary hover:outline-light-secondary font-medium tracking-wide
                   dark:bg-dark-accent dark:text-dark-text dark:hover:bg-dark-accent dark:border-dark-primary dark:hover:outline-dark-secondary"
            >
              Delete My Information
            </button>
          )}
        </div>
        <div>Have a great day.</div>
        <br />
        <div>
          Didn't mean to unsubscribe?{" "}
          <a
            className="text-light-accent dark:text-dark-accent font-bold underline"
            href="https://brief-signup.web.app"
          >
            Re-Subscribe here!
          </a>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="p-7 font-bold w-full h-screen flex flex-col justify-center text-lg
    text-light-text bg-light-neutral
    dark:text-dark-text dark:bg-dark-neutral"
      >
        <div className="text-4xl mb-3">Sorry to see you go!</div>
        <div className="mb-2">
          <span className="text-red-500 dark:text-red-500">
            There was an error unsubscribing you from brief.
          </span>
          .
        </div>
        <div className="mb-3">
          To unsubscribe, please click the link below to send an email using the
          address you would like to be unsubscribed from.
        </div>
        <div>
          <a
            href={`mailto:support@itsbrief.com?subject=Unsubscribe from the brief newsletter. Email: ${email}&body=Please unsubscribe me from the brief newsletter.`}
            className="rounded-xl border-2 px-4 py-2 mb-2
                     outline outline-2 outline-transparent hover:outline-offset-2 transition-all
                        bg-light-accent text-light-textInputSecondary hover:bg-light-accent border-light-primary hover:outline-light-secondary font-medium tracking-wide
                        dark:bg-dark-accent dark:text-dark-text dark:hover:bg-dark-accent dark:border-dark-primary dark:hover:outline-dark-secondary"
          >
            Unsubscribe
          </a>
        </div>
      </div>
    );
  }
}
