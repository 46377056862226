// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD8ODnXmgpBLlqwK7HP2KfqUT2iTlN6u6Y",
  authDomain: "brief-signup.firebaseapp.com",
  projectId: "brief-signup",
  storageBucket: "brief-signup.appspot.com",
  messagingSenderId: "393904469464",
  appId: "1:393904469464:web:30dd2fe635c9e3d15beead",
  measurementId: "G-LCTBTJD2GF",
};
// Initialize Firebase

const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);
export const functions = getFunctions(app);
