import React from "react";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <div>
      <div
        className="lg:p-56 md:p-32 p-16 font-bold w-full h-screen flex flex-col justify-center align-center text-lg
    text-light-text bg-light-background
    dark:text-dark-text dark:bg-dark-neutral"
      >
        <div className="text-4xl mb-3">
          Welcome to <span className="text-light-accent">brief!</span>
        </div>
        <div className="mb-2">
          <span className="text-light-text dark:text-white">
            brief is a daily email newsletter that delivers the most important
            news, catered to you.
          </span>
        </div>
        <div className="mb-3">
          <span className="text-light-text dark:text-white">
            We know you're busy, so we'll keep it brief.
          </span>
        </div>
        {/* <div className="mb-3">
          <span className="text-light-text dark:text-white">
            Click below to get started.
          </span>
        </div> */}
        <Link to="/signup" relative="path">
          <button
            className="rounded-xl border-2 px-4 py-2 mb-2
        outline outline-2 outline-transparent hover:outline-offset-2 transition-all text-lg
        bg-light-accent text-light-textInputSecondary hover:bg-light-accent border-light-primary hover:outline-light-secondary font-medium tracking-wide
        dark:bg-dark-accent dark:text-dark-text dark:hover:bg-dark-accent dark:border-dark-primary dark:hover:outline-dark-secondary"
          >
            Take me to your sign up page
          </button>
        </Link>
      </div>
    </div>
  );
}
