import { useState, useEffect } from "react";
import { functions } from "../firebase";
import { httpsCallable } from "firebase/functions";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";

// import {
//   CreateNewsletterUser,
//   CheckForUser,
// } from "../cloudFirestore/databaseFunctions";

import "../index.css";

const createUser = httpsCallable(functions, "createUser");

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

const validationSchema = yup.object({
  categories: yup.array(),
  name: yup.string("Enter your name").required("Name is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

export default function SignUp() {
  const [country, setCountry] = useState(null);
  const [timezone, setTimezone] = useState(null);
  const [userExists, setUserExists] = useState(false);
  const [UTCOffset, setUTCOffset] = useState(null);
  const [signedUp, setSignedUp] = useState(false);

  let categories = [
    country ? country : null,
    "Business",
    "Technology",
    "Sports",
    "Entertainment",
    "Science",
    "Health",
    "World",
  ];

  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((res) => res.json())
      .then((response) => {
        setTimezone(response.timezone);
        setUTCOffset(response.utc_offset);
        setCountry(response.country_name);
      })
      .catch((data, status) => {
        console.log("Request failed");
      });
  }, []);

  return signedUp ? (
    <div
      className={`font-sans h-screen w-full lg:p-56 md:p-32 p-16
                  text-light-text bg-light-background
                  dark:text-dark-text dark:bg-dark-neutral`}
    >
      <div className="min-w-fit container mx-auto">
        <h1 className="text-6xl font-bold text-left tracking-wide p-2">
          {" "}
          brief.
        </h1>
        <h4 className="text-2xl font-bold text-left tracking-wide px-2 pb-2">
          Thank you for signing up,{" "}
          <span className="text-light-accent dark:text-dark-accent">
            {signedUp.name}!
          </span>
        </h4>
        <h2 className="text-2xl font-bold text-left tracking-wide px-2 pb-2">
          We will send you a confirmation email shortly.
        </h2>
      </div>
    </div>
  ) : (
    <div
      className={`font-sans h-screen w-full lg:p-56 md:p-32 xs:pt-24 xs:px-8 
                  text-light-text bg-light-background
                  dark:text-dark-text dark:bg-dark-neutral`}
    >
      <div className="min-w-fit xs:grid xs:gap-y-4 md:gap-y-0">
        <div>
          <h1 className="text-6xl font-bold text-left tracking-wide p-2">
            {" "}
            brief.
          </h1>
          <h4 className="text-2xl font-bold text-left tracking-wide px-2 pb-2">
            Get the news you want, when you want it.
          </h4>
        </div>
        <Formik
          initialValues={{
            categories: [],
            name: "",
            email: "",
            country: country,
            timezone: "",
            utc_offset: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            await sleep(500);
            createUser(values)
              .then((result) => {
                if (result.data.status === "success") {
                  setSignedUp(values);
                }
              })
              .catch((error) => {
                if (error.data.status === "user_exists") {
                  setUserExists(true);
                }
              });
          }}
        >
          {({ errors, touched, values }) => {
            const checkboxDivClassNames = (valueCheck) =>
              `xs:flex md:h-fit lg:hover:drop-shadow-lg lg:hover:-translate-x-px lg:hover:-translate-y-px
               lg:hover:scale-105 border-2 xs:w-min md:w-full xs:rounded-full md:rounded-md mx-0.5 my-1 transition-all xs:bg-light-neutral xs:dark:bg-transparent ${
                 values.categories.includes(valueCheck)
                   ? `xs:text-dark-accent xs:border-dark-accent md:bg-light-neutral md:hover:bg-light-neutral md:text-light-text
                      dark:text-light-primary dark:md:bg-dark-neutral dark:md:hover:bg-dark-neutral dark:md:text-light-primary 
                      `
                   : `xs:border-transparent xs:dark:border-dark-neutralAccent
                      dark:text-dark-textSecondary text-light-text`
               }`;
            const checkboxClassNames = `transition-all md:checked:animate-pinged 
                                        opacity-0 w-0 h-0 m-0 md:opacity-100 md:w-4 md:h-4 md:ml-2 md:my-auto 
                                        accent-light-accent
                                        dark:accent-dark-accent`;
            const checkboxLabelClassNames = `md:ml-0 md:mt-0.5 m-1 xs:px-2 sm:px-4 xs:text-[20px]
                                            text-xl font-medium text-center align-top md:text-left tracking-wide`;
            const inputClassNames = `rounded-xl w-full p-1 px-3 md:py-2 mb-1 hover:drop-shadow-lg
                                     md:hover:-translate-x-px md:hover:-translate-y-px md:hover:scale-105 border-2 outline outline-2 outline-transparent md:hover:outline-offset-2 focus:outline-offset-2 transition-all
                                     bg-light-neutral md:hover:bg-light-secondary focus:bg-light-secondary text-light-textInput text-lg md:hover:text-light-textInputSecondary focus:text-light-textInputSecondary font-medium placeholder:text-light-textPlaceholder md:hover:placeholder:text-light-textPlaceholderSecondary focus:placeholder:text-light-textPlaceholderSecondary border-light-primary md:hover:outline-light-accent focus:outline-light-accent
                                     dark:bg-dark-neutral dark:md:hover:bg-dark-secondary dark:focus:bg-dark-secondary dark:text-dark-textInput dark:md:hover:text-dark-textInputSecondary dark:focus:text-dark-textInputSecondary dark:placeholder:text-dark-textPlaceholder dark:md:hover:placeholder:text-dark-textPlaceholderSecondary dark:focus:placeholder:text-dark-textPlaceholderSecondary dark:border-dark-primary dark:md:hover:outline-dark-accent dark:focus:outline-dark-accent`;
            const submitClassNames = `rounded-xl w-full p-1 px-3 md:py-2.5 lg:hover:drop-shadow-lg lg:hover:-translate-x-px lg:hover:-translate-y-px lg:hover:scale-105 border-2
                                      outline outline-2 outline-transparent hover:outline-offset-2 transition-all
                                      bg-light-accent text-white hover:bg-light-accent border-light-primary hover:outline-light-secondary font-medium text-xl tracking-wide
                                      dark:bg-dark-accent dark:text-dark-text dark:hover:bg-dark-accent dark:border-dark-primary dark:hover:outline-dark-secondary`;
            return (
              <Form>
                <div className="grid md:grid-cols-3 xs:h-min m-auto xs:gap-y-4">
                  <div className="xs:flex xs:flex-wrap md:grid md:col-span-2 md:grid-cols-2 md:grid-rows-4 md:gap-x-4 md:h-min">
                    {categories
                      .slice(country ? 0 : 1, 8)
                      .map((category) => (
                        <label key={category}>
                          <div
                            className={checkboxDivClassNames(
                              category === country ? "Country" : category
                            )}
                            style={{ "&hover": "color: 'black'" }}
                          >
                            <Field
                              type="checkbox"
                              name="categories"
                              className={checkboxClassNames}
                              value={
                                category === country ? "Country" : category
                              }
                            />
                            <div className={checkboxLabelClassNames}>
                              {category}
                            </div>
                          </div>
                        </label>
                      ))}
                  </div>
                  <div className="items-center md:my-1 pt-3 md:pt-0 px-2">
                    <Field
                      id="name"
                      name="name"
                      placeholder="Name"
                      type="name"
                      className={inputClassNames}
                    />
                    <br />
                    <Field
                      id="email"
                      name="email"
                      placeholder="Email Address"
                      type="email"
                      className={inputClassNames}
                    />
                    <br />
                    <button
                      type="submit"
                      className={submitClassNames}
                      onClick={() => {
                        values.country = country;
                        values.timezone = timezone;
                        values.utc_offset = UTCOffset;
                      }}
                    >
                      Go!
                    </button>
                    {userExists ? (
                      <div className="text-red-500">
                        You are already subscribed!
                      </div>
                    ) : null}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
