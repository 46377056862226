import React from "react";

export default function NotFound() {
  return (
    <div
      className="p-7 font-bold w-full h-screen
    text-light-accent bg-light-neutral
    dark:text-dark-accent dark:bg-dark-neutral"
    >
      <div className="text-4xl mb-3">404</div>
      <div className="mb-2">
        <span className="text-light-text dark:text-dark-text">
          Page not found.
        </span>
      </div>
      <div className="mb-2">
        <span className="text-light-text dark:text-dark-text">
          The page you are looking for does not exist.
        </span>
        <a href="/" className="text-light-accent dark:text-dark-accent">
          {" "}
          Click here to go back home.
        </a>
      </div>
    </div>
  );
}
